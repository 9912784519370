import React, {useCallback, useEffect, useState} from 'react'
import {HeaderWrapper} from './TransparentNavbarMobile.styles'
import {
  Marquee,
  OptimizedImage,
  Profile,
  Typography,
  TypographyVariants,
} from '@web-components'
import {GenericAction} from '@mosaic-wellness/fe-types'
import {useGenericActions} from '../../../hooks/useGenericActions'
import {useIntersection} from '../../../hooks/useIntersection'
import {ICenterLogoNavMobile} from './TransparentNavbarMobile.interface'

const TransparentNavbarMobile = (props: ICenterLogoNavMobile) => {
  const {
    hamburgerIcon = '',
    search,
    logo,
    cartData,
    items = [],
    crossIcon = '',
    shopNowCta,
    profile,
    marquee,
    cartTotalItems = 0,
    isSticky = true,
    isLoggedIn,
  } = props || {}

  const {list = []} = marquee || {}

  const {handleGenericActions} = useGenericActions()
  const [isNavVisible, setIsNavVisible] = useState(false)

  const toggleNavbar = useCallback(() => {
    const analyticsEvent = {
      actionName: 'TRIGGER_EVENTS_FOR_WEB',
      params: {
        event: 'Hamburger Clicked',
      },
    }
    handleGenericActions([analyticsEvent])
    setIsNavVisible((prev) => !prev)
  }, [handleGenericActions])

  const navClick = useCallback(
    (actions: GenericAction[]) => {
      if (actions) {
        const {params} = actions[0]

        const analyticsEvent = {
          actionName: 'TRIGGER_EVENTS_FOR_WEB',
          params: {
            event: 'Nav Link Clicked',
            eventData: {
              location: 'HamburgerMenu',
              URL: window.location.pathname,
              ...params,
            },
          },
        }
        handleGenericActions([...actions, analyticsEvent])
      }
    },
    [handleGenericActions]
  )

  useEffect(() => {
    if (isNavVisible) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }

    return () => {
      document.body.style.overflow = ''
    }
  }, [isNavVisible])

  const {isIntersecting, nodeRef} = useIntersection({
    rootMargin: '38px',
    threshold: 0.1,
    keepObserving: true,
    initialValue: true,
  })

  return (
    <>
      <HeaderWrapper
        data-is-background-white={!isIntersecting}
        data-is-sticky={isSticky}
      >
        {isIntersecting && <Marquee items={list} />}
        <div className="mobile-nav">
          <div className="left-icons">
            <button className="nav-btn" onClick={toggleNavbar}>
              <OptimizedImage
                source={hamburgerIcon}
                customClassName="nav-icon"
                mobileViewWidth="SMALL"
              />
            </button>
            {search?.image && (
              <button
                className="nav-btn"
                onClick={() => navClick(search.actions)}
              >
                <OptimizedImage
                  source={search.image}
                  customClassName="nav-icon"
                  mobileViewWidth="SMALL"
                />
              </button>
            )}
          </div>
          <button
            className="nav-btn brand-logo-btn"
            onClick={() => navClick(logo.actions)}
          >
            {logo?.image && (
              <div className="brand-logo-container">
                <OptimizedImage
                  source={logo.image}
                  customClassName="brand-logo"
                  mobileViewWidth="THIRD"
                />
              </div>
            )}
          </button>
          <div className="right-icons">
            <button
              className="nav-btn"
              onClick={() => navClick(profile?.actions)}
            >
              <Profile size="24" />
            </button>
            <button
              className="nav-btn cart-btn"
              onClick={() => navClick(cartData?.actions)}
            >
              {cartTotalItems > 0 && (
                <div className="item-count">
                  <Typography variant={TypographyVariants.CTA_LABEL_SMALL}>
                    {cartTotalItems}
                  </Typography>
                </div>
              )}
              {cartData?.image && (
                <OptimizedImage
                  source={cartData.image}
                  customClassName="nav-icon"
                  mobileViewWidth="SMALL"
                />
              )}
            </button>
          </div>

          {isNavVisible && (
            <div className="nav-overlay" onClick={toggleNavbar} />
          )}
          <nav className={isNavVisible ? 'responsive_nav' : ''}>
            <div className="mobile-nav-header">
              <button className="nav-btn " onClick={toggleNavbar}>
                <OptimizedImage
                  source={crossIcon}
                  customClassName="cross-icon"
                  mobileViewWidth="SMALL"
                />
              </button>
              <button className="nav-btn">
                {logo?.image && (
                  <div className="brand-logo-container">
                    <OptimizedImage
                      source={logo.image}
                      customClassName="brand-logo"
                      mobileViewWidth="THIRD"
                    />
                  </div>
                )}
              </button>
              <button
                className="nav-btn"
                onClick={() => navClick(cartData.actions)}
              >
                {cartData?.image && (
                  <OptimizedImage
                    source={cartData.image}
                    customClassName="nav-icon"
                    mobileViewWidth="SMALL"
                  />
                )}
              </button>
            </div>
            <div className="nav-menu">
              {items.map((item: any, index: number) => (
                <button onClick={() => navClick(item.actions)} key={index}>
                  <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
                    {item.label}
                  </Typography>
                </button>
              ))}

              <button
                onClick={() => navClick(shopNowCta?.actions)}
                className="mobile-nav-menu-shopnow"
              >
                <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
                  {shopNowCta?.label}
                </Typography>
              </button>
              {!isLoggedIn && (
                <button
                  onClick={() => navClick(profile.actions)}
                  className="mobile-nav-menu-profile"
                >
                  <Profile size="18" />
                  <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
                    {profile?.label}
                  </Typography>
                </button>
              )}
            </div>
          </nav>
        </div>
      </HeaderWrapper>
      <div className="stick-navbar" ref={nodeRef}></div>
    </>
  )
}

export default TransparentNavbarMobile
