import styled from 'styled-components'

export const HeaderWrapper = styled.header`
  background: linear-gradient(180deg, #fef8f3bd 10%, #fef9f300 100%);
  position: fixed;
  top: 0;
  z-index: 4;
  width: 100%;
  transition: all 0.2s;
  color: var(--dls-brand-primary-color-500);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  &[data-is-sticky='true'] {
    position: sticky;
    top: 0;
  }
  &[data-is-background-white='true'] {
    background: var(--dls-backgound-color-500);
  }

  button {
    &:focus {
      outline: none !important;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      cursor: pointer;
    }
  }

  .desktop-nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 50px 100px;
    transition: all 0.35s;

    @media (max-width: 1200px) {
      padding: 25px 20px;
    }

    .desktop-logo {
      width: auto;
      height: 46px;
      object-fit: cover;
      cursor: pointer;
    }
  }

  .desktop-nav-section {
    width: 100%;
  }
  .desktop-nav nav {
    flex: 1;
    display: flex;
    justify-content: center;
    gap: var(--dls-size-48);
    width: 100%;
    margin-left: 12%;
    flex-wrap: wrap;

    @media (max-width: 1200px) {
      margin-left: 6%;
      justify-content: flex-start;
    }
  }

  .right-nav-icon-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--dls-size-24);
    width: 25%;
  }
  .nav-icon {
    height: var(--dls-size-24);
    width: var(--dls-size-24);
  }

  .desktop-right-icon-holder {
    position: relative;
    display: flex;
    align-items: center;
    gap: var(--dls-size-16);
    cursor: pointer;
  }

  .item-count-desktop {
    position: absolute;
    top: -10px;
    left: 16px;
    background-color: var(--dls-brand-primary-color-500);
    padding: 3px;
    color: var(--dls-white-color);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .desktop-right-btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 44px;
    width: 44px;
    border: 2px solid var(--dls-brand-primary-color-500);
    border-radius: var(--dls-size-24);
    padding: 9px;
    cursor: pointer;
    color: var(--dls-brand-primary-color-500);
    background-color: transparent;
    transition: color 0.3s ease;
  }

  .desktop-right-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: var(--dls-brand-primary-color-500);
    z-index: 0;
    transition: left 0.2s ease;
    clip-path: polygon(0 0, 75% 0, 100% 100%, 0% 100%);
  }

  .desktop-right-btn:hover::before {
    left: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }

  .desktop-right-btn:hover {
    color: white;
    svg path {
      stroke: var(--dls-white-color);
    }
  }

  .desktop-right-btn > * {
    position: relative;
    z-index: 1;
  }

  .desktop-shop-btn {
    position: relative;
    display: inline-block;
    overflow: hidden;
    border: 2px solid var(--dls-brand-primary-color-500);
    padding: var(--dls-size-8) var(--dls-size-16);
    border-radius: var(--dls-size-24);
    cursor: pointer;
    color: var(--dls-brand-primary-color-500);
    background-color: transparent;
    transition: color 0.3s ease;
    flex-shrink: 0;
  }

  .desktop-shop-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: var(--dls-brand-primary-color-500);
    z-index: 0;
    transition: left 0.2s ease;
    clip-path: polygon(0 0, 75% 0, 100% 100%, 0% 100%);
  }

  .desktop-shop-btn:hover::before {
    left: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }

  .desktop-shop-btn:hover {
    color: white;
  }

  .desktop-shop-btn > * {
    position: relative;
    z-index: 1;
  }
  nav button {
    color: var(--dls-color-white);
    background: none;
    text-decoration: none;
    border: none;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      background-color: var(--dls-brand-primary-color-500);
      border-radius: 50%;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
    }

    &:hover::after {
      opacity: 1;
    }
  }
`
