import styled from 'styled-components'

export const HeaderWrapper = styled.header`
  background: linear-gradient(180deg, #fef8f3bd 10%, #fef9f300 100%);
  position: fixed;
  z-index: 4;
  width: 100%;
  transition: all 0.2s;
  color: var(--dls-brand-primary-color-500);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &[data-is-sticky='true'] {
    position: sticky;
    top: 0;
  }

  &[data-is-background-white='true'] {
    background: var(--dls-backgound-color-500);
  }

  .mobile-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px var(--dls-size-16);
  }
  button {
    &:focus {
      outline: none !important;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      cursor: pointer;
    }
  }

  .nav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9;
  }

  .nav-icon {
    height: var(--dls-size-24);
    width: var(--dls-size-24);
  }

  .cross-icon {
    height: var(--dls-size-16);
    width: var(--dls-size-16);
  }

  nav button {
    color: var(--dls-color-white);
    background: none;
    text-decoration: none;
    border: none;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    gap: var(--dls-size-16);
    width: 100%;
    padding: 0 var(--dls-size-8);
  }

  .nav-btn {
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--dls-brand-primary-color-500);
    visibility: hidden;
    opacity: 0;
    width: var(--dls-size-40);
  }

  .brand-logo-btn {
    flex-grow: 1;
  }
  .cart-btn {
    position: relative;
  }

  .item-count {
    position: absolute;
    top: -5px;
    right: 0;
    background-color: var(--dls-brand-primary-color-500);
    padding: 3px;
    color: var(--dls-white-color);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left-icons {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .right-icons {
    display: flex;
    height: 100%;
    justify-content: space-between;
    gap: var(--dls-size-16);
  }

  @media only screen and (max-width: 1024px) {
    .nav-btn {
      visibility: visible;
      opacity: 1;
    }

    nav {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 14px;
      background-color: var(--dls-backgound-color-500);

      transform: translateY(-100vh);
    }

    .responsive_nav {
      transform: none;
      transition: ease-in-out 0.5s;
      z-index: 999;
      border-radius: 0 0 25px 25px;
      padding-bottom: 12px;
    }

    .brand-logo-container {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
    }

    .brand-logo {
      max-width: 100px;
      height: auto;
      object-fit: cover;
    }

    .mobile-nav-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: var(--dls-size-16) var(--dls-size-16);
    }

    .mobile-nav-menu-shopnow {
      background-color: var(--dls-brand-primary-color-500);
      color: var(--dls-white-color);
      padding: var(--dls-size-8);
      border-radius: 25px;
    }
    .mobile-nav-menu-profile {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      color: var(--dls-brand-primary-color-500);
      border: 2px solid var(--dls-brand-primary-color-500);
      padding: var(--dls-size-8);
      border-radius: 25px;
      gap: var(--dls-size-8);
    }
  }
`
