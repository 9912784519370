import React, {useCallback, useMemo} from 'react'
import {HeaderWrapper} from './TransparentNavbarDesktop.styles'
import {
  OptimizedImage,
  Typography,
  TypographyVariants,
  Profile,
  ShoppingCart,
  Marquee,
} from '@web-components'
import {GenericAction} from '@mosaic-wellness/fe-types'
import {ICenterLogoNavDesktop} from './TransparentNavbarDesktop.interface'
import {useIntersection} from '../../../hooks/useIntersection'
import {useGenericActions} from '../../../hooks/useGenericActions'

const TransparentNavbarDesktop = (props: ICenterLogoNavDesktop) => {
  const {
    logo,
    cartData,
    items = [],
    profile,
    shopNowCta,
    marquee,
    cartTotalItems = 0,
    isSticky = true,
  } = props

  const {list = []} = marquee || {}

  const {isIntersecting, nodeRef} = useIntersection({
    rootMargin: '0px',
    threshold: 1,
    keepObserving: true,
    initialValue: true,
  })

  const {handleGenericActions} = useGenericActions()

  const navClick = useCallback(
    (actions: GenericAction[]) => {
      const {params} = actions[0]

      const analyticsEvent = {
        actionName: 'TRIGGER_EVENTS_FOR_WEB',
        params: {
          event: 'Nav Link Clicked',
          eventData: {
            location: 'NavBar',
            URL: window.location.pathname,
            ...params,
          },
        },
      }
      handleGenericActions([...actions, analyticsEvent])
    },
    [handleGenericActions]
  )

  return (
    <>
      <div className="stick-navbar" ref={nodeRef}></div>
      <HeaderWrapper
        data-is-background-white={!isIntersecting}
        data-is-sticky={isSticky}
      >
        {isIntersecting && <Marquee items={list} />}
        <div className="desktop-nav">
          <div className="desktop-nav-section">
            <nav>
              {items
                .slice(0, items.length / 2)
                .map((item: any, index: number) => (
                  <button onClick={() => navClick(item.actions)} key={index}>
                    <Typography variant={TypographyVariants.TAG_REGULAR}>
                      {item.label}
                    </Typography>
                  </button>
                ))}
              {logo?.image ? (
                <OptimizedImage
                  source={logo.image}
                  onImageClick={() => {
                    navClick(logo.actions)
                  }}
                  desktopViewWidth="THIRD"
                  customClassName="desktop-logo"
                />
              ) : null}
              {items
                .slice(items.length / 2, items.length)
                .map((item: any, index: number) => (
                  <button onClick={() => navClick(item.actions)} key={index}>
                    <Typography variant={TypographyVariants.TAG_REGULAR}>
                      {item?.label}
                    </Typography>
                  </button>
                ))}
            </nav>
          </div>
          <div className="right-nav-icon-wrapper">
            <div
              className="desktop-right-icon-holder "
              onClick={() => navClick(profile.actions)}
            >
              <div className="desktop-right-btn">
                <Profile size="24" />
              </div>
            </div>
            <div
              className="desktop-right-icon-holder "
              onClick={() => navClick(cartData.actions)}
            >
              <div className="desktop-right-btn">
                <ShoppingCart size="24" />
              </div>

              {cartTotalItems > 0 && (
                <div className="item-count-desktop">
                  <Typography variant={TypographyVariants.X_SMALL}>
                    {cartTotalItems}
                  </Typography>
                </div>
              )}
            </div>

            <div
              className="desktop-shop-btn"
              onClick={() => navClick(shopNowCta.actions)}
            >
              <Typography variant={TypographyVariants.TAG_REGULAR}>
                {shopNowCta?.label}
              </Typography>
            </div>
          </div>
        </div>
      </HeaderWrapper>
    </>
  )
}

export default TransparentNavbarDesktop
