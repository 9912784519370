import {toast as reactToast, Renderable} from 'react-hot-toast'
import React from 'react'
import {Subject, throttleTime} from 'rxjs'
import {SuccessIcon, WarningIcon, InternetIcon} from '@web-components'
import {ToastType} from '@mosaic-wellness/fe-types'

const toastObservable = new Subject<{type: ToastType; message: string}>()

const Toast = (type: ToastType, message: string) => {
  toastObservable.next({type, message})
}
export default Toast

toastObservable
  .pipe(throttleTime(1500, undefined, {leading: true, trailing: true}))
  .subscribe({
    next: (value) => {
      const {type, message} = value
      try {
        switch (type) {
          case 'success':
            toastRender(
              <SuccessIcon />,
              message || 'Success',
              'var(--brand-primary-teal-color)'
            )
            break
          case 'error':
            toastRender(
              <WarningIcon />,
              message || 'Warning',
              'var(--brand-primary-error-color)'
            )
            break
          case 'info':
            toastRender(
              <WarningIcon />,
              message || 'No Internet Connection',
              'var(--brand-primary-orange-color)'
            )
            break
          default:
            return
        }
      } catch (e) {
        return
      }
    },
  })

function toastRender(
  icon: Renderable,
  message: string,
  backgroundColor: string
) {
  reactToast(message, {
    position: 'top-center',
    icon,
    style: {
      backgroundColor,
      color: 'white',
      borderRadius: '20px',
    },
    duration: 3000,
  })
}
